/* FONTS */
@font-face {
  font-family: 'Clear sans';
  src: local('Clear sans'), url(./fonts/Clear-sans-thin.ttf) format('truetype');
  font-display: auto;
}
@font-face {
  font-family: 'Alice regular';
  src: local('Alice regular'), url(./fonts/Alice-Regular.ttf) format('truetype');
  font-display: auto;

}
@font-face {
  font-family: 'Cormorant meduim';
  src: local('Cormorant meduim'), url(./fonts/CormorantGaramond-Medium.otf) format('opentype');
  font-display: auto;

}

/* HOMEPAGE */ 

.FirstPhotoContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.FirstPhoto {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-image: url("./mains/pages/eyehead.jpeg");
  background-size: cover;
  transition: ease-in-out 0.3s;
  z-index: 2;
}
.FirstPhoto:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: inherit;
  background-position: bottom;
  filter: blur(40px) saturate(0%);
  transform: scaleX(0.4);
  transition: ease-in-out 0.4s;
  border-radius: 120px;
  transform-origin: left;
  opacity: 0;
  z-index: -1;
}
.FirstPhoto .container__info {
  position: relative;
  line-height: 1.8;
  transition: ease-in-out 0.3s;
  opacity: 0;
}
.FirstPhoto .container__location {
  transition-delay: 0.15s;
}
.FirstPhoto:hover {
  border-radius: 0;
  width: 450px;
  height: 310px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(9, 55, 53, 0.08), 0px 16px 24px rgba(9, 55, 53, 0.1), 0px 24px 32px rgba(9, 55, 53, 0.14);
}
.FirstPhoto:hover:before {
  width: 100%;
  opacity: 0.18;
  filter: blur(10px) saturate(100%);
  transform: scale(2.8) translate3d(18%, 0px, 0px);
}
.FirstPhoto:hover .container__info {
  transform: translate3d(120%, 0px, 0px);
  opacity: 1;
}

.SecondPhotoContainer{
  display: flex;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.SecondPhoto {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-image: url("./mains/pages/FIRST.jpeg");
  background-size: cover;
  transition: ease-in-out 0.3s;
  z-index: 2;
}
.SecondPhoto:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: inherit;
  background-position: bottom;
  filter: blur(40px) saturate(0%);
  transform: scaleX(0.4);
  transition: ease-in-out 0.4s;
  border-radius: 120px;
  transform-origin: left;
  opacity: 0;
  z-index: -1;
}
.SecondPhoto .container__info {
  position: relative;
  line-height: 1.8;
  transition: ease-in-out 0.3s;
  opacity: 0;
}
.SecondPhoto .container__location {
  transition-delay: 0.15s;
}
.SecondPhoto:hover {
  border-radius: 0;
  width: 450px;
  height: 310px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(9, 55, 53, 0.08), 0px 16px 24px rgba(9, 55, 53, 0.1), 0px 24px 32px rgba(9, 55, 53, 0.14);
}
.SecondPhoto:hover:before {
  width: 100%;
  opacity: 0.18;
  filter: blur(5px) saturate(100%);
  transform: scale(2.8) translate3d(-18%, 0px, 0px);
}
.SecondPhoto:hover .container__info {
  transform: translate3d(120%, 0px, 0px);
  opacity: 1;
}

.pieces{
  display: grid;
  place-content: center;
  overflow: hidden;
  width: 100%;
}

.frame {
  display: grid;
  width: 50vmin;
  height: 50vmin;
  background: rgb(255, 255, 255);
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
}
.frame .slice {
  width: calc(100% + 0.5px);
  height: calc(100% + 0.5px);
  background-image:  url(./mains/pages/patterns.png);
  background-size: 800% 800%;
  position: relative;
}
.frame .slice:nth-of-type(1) {
  transition: all 1.54367561s;
  background-position: 0% 0%;
  transform: translateX(-37.76233624vw) translateY(-9.00686519vh) rotate(0.02933124turn);
}
.frame .slice:nth-of-type(2) {
  transition: all 1.17670554s;
  background-position: 14.28571429% 0%;
  transform: translateX(-11.70642037vw) translateY(21.02619793vh) rotate(0.40363704turn);
}
.frame .slice:nth-of-type(3) {
  transition: all 1.33844994s;
  background-position: 28.57142857% 0%;
  transform: translateX(-22.86647016vw) translateY(15.61273253vh) rotate(0.33900224turn);
}
.frame .slice:nth-of-type(4) {
  transition: all 1.87086431s;
  background-position: 42.85714286% 0%;
  transform: translateX(-30.5538687vw) translateY(-21.50192264vh) rotate(0.15349889turn);
}
.frame .slice:nth-of-type(5) {
  transition: all 1.75374472s;
  background-position: 57.14285714% 0%;
  transform: translateX(-29.51227632vw) translateY(-5.48563247vh) rotate(0.80636497turn);
}
.frame .slice:nth-of-type(6) {
  transition: all 1.10022684s;
  background-position: 71.42857143% 0%;
  transform: translateX(-6.39983749vw) translateY(9.67298019vh) rotate(0.75255538turn);
}
.frame .slice:nth-of-type(7) {
  transition: all 1.54150826s;
  background-position: 85.71428571% 0%;
  transform: translateX(38.18636715vw) translateY(22.63827679vh) rotate(0.28974239turn);
}
.frame .slice:nth-of-type(8) {
  transition: all 1.33511101s;
  background-position: 100% 0%;
  transform: translateX(-14.55824052vw) translateY(-11.96237622vh) rotate(0.40974885turn);
}
.frame .slice:nth-of-type(9) {
  transition: all 1.32767981s;
  background-position: 0% 14.28571429%;
  transform: translateX(28.87983261vw) translateY(12.91305628vh) rotate(0.77209077turn);
}
.frame .slice:nth-of-type(10) {
  transition: all 1.4018207s;
  background-position: 14.28571429% 14.28571429%;
  transform: translateX(4.85999803vw) translateY(11.78084218vh) rotate(0.08054461turn);
}
.frame .slice:nth-of-type(11) {
  transition: all 1.88276008s;
  background-position: 28.57142857% 14.28571429%;
  transform: translateX(33.85151791vw) translateY(-13.36686581vh) rotate(0.76264777turn);
}
.frame .slice:nth-of-type(12) {
  transition: all 1.69435302s;
  background-position: 42.85714286% 14.28571429%;
  transform: translateX(30.25471864vw) translateY(17.81118902vh) rotate(0.82392046turn);
}
.frame .slice:nth-of-type(13) {
  transition: all 1.50219518s;
  background-position: 57.14285714% 14.28571429%;
  transform: translateX(33.32437906vw) translateY(11.21539429vh) rotate(0.07427674turn);
}
.frame .slice:nth-of-type(14) {
  transition: all 1.42913184s;
  background-position: 71.42857143% 14.28571429%;
  transform: translateX(-31.8739615vw) translateY(3.43894852vh) rotate(0.2495423turn);
}
.frame .slice:nth-of-type(15) {
  transition: all 1.83774417s;
  background-position: 85.71428571% 14.28571429%;
  transform: translateX(17.05879282vw) translateY(-14.58979676vh) rotate(0.27302764turn);
}
.frame .slice:nth-of-type(16) {
  transition: all 1.82578369s;
  background-position: 100% 14.28571429%;
  transform: translateX(-20.75113224vw) translateY(12.77901737vh) rotate(0.1884633turn);
}
.frame .slice:nth-of-type(17) {
  transition: all 1.64610986s;
  background-position: 0% 28.57142857%;
  transform: translateX(-40.49430429vw) translateY(-13.3754222vh) rotate(0.85621917turn);
}
.frame .slice:nth-of-type(18) {
  transition: all 1.4664628s;
  background-position: 14.28571429% 28.57142857%;
  transform: translateX(-4.30103724vw) translateY(0.1711578vh) rotate(0.39768846turn);
}
.frame .slice:nth-of-type(19) {
  transition: all 1.69982068s;
  background-position: 28.57142857% 28.57142857%;
  transform: translateX(22.53468555vw) translateY(-18.68041058vh) rotate(0.71601703turn);
}
.frame .slice:nth-of-type(20) {
  transition: all 1.40718377s;
  background-position: 42.85714286% 28.57142857%;
  transform: translateX(26.90085302vw) translateY(9.84392237vh) rotate(0.38617815turn);
}
.frame .slice:nth-of-type(21) {
  transition: all 1.63398855s;
  background-position: 57.14285714% 28.57142857%;
  transform: translateX(38.72083592vw) translateY(1.23773681vh) rotate(0.15397886turn);
}
.frame .slice:nth-of-type(22) {
  transition: all 1.48836528s;
  background-position: 71.42857143% 28.57142857%;
  transform: translateX(-26.70285246vw) translateY(17.75571472vh) rotate(0.6204806turn);
}
.frame .slice:nth-of-type(23) {
  transition: all 1.86838749s;
  background-position: 85.71428571% 28.57142857%;
  transform: translateX(-44.5941591vw) translateY(20.33333917vh) rotate(0.24469195turn);
}
.frame .slice:nth-of-type(24) {
  transition: all 1.84333253s;
  background-position: 100% 28.57142857%;
  transform: translateX(-44.02796151vw) translateY(-23.05156101vh) rotate(0.83126221turn);
}
.frame .slice:nth-of-type(25) {
  transition: all 1.41221091s;
  background-position: 0% 42.85714286%;
  transform: translateX(-42.5377407vw) translateY(-16.90549655vh) rotate(0.69289142turn);
}
.frame .slice:nth-of-type(26) {
  transition: all 1.74926211s;
  background-position: 14.28571429% 42.85714286%;
  transform: translateX(-1.98435242vw) translateY(6.77516106vh) rotate(0.91283932turn);
}
.frame .slice:nth-of-type(27) {
  transition: all 1.86112816s;
  background-position: 28.57142857% 42.85714286%;
  transform: translateX(-49.4553303vw) translateY(-24.76821029vh) rotate(0.374139turn);
}
.frame .slice:nth-of-type(28) {
  transition: all 1.29952241s;
  background-position: 42.85714286% 42.85714286%;
  transform: translateX(23.63738641vw) translateY(14.48869244vh) rotate(0.94566196turn);
}
.frame .slice:nth-of-type(29) {
  transition: all 1.40078418s;
  background-position: 57.14285714% 42.85714286%;
  transform: translateX(36.5226184vw) translateY(-13.53413162vh) rotate(0.90573466turn);
}
.frame .slice:nth-of-type(30) {
  transition: all 1.19353711s;
  background-position: 71.42857143% 42.85714286%;
  transform: translateX(-45.03106051vw) translateY(-23.06249615vh) rotate(0.8110362turn);
}
.frame .slice:nth-of-type(31) {
  transition: all 1.85992647s;
  background-position: 85.71428571% 42.85714286%;
  transform: translateX(38.08036982vw) translateY(-19.09271396vh) rotate(0.84376099turn);
}
.frame .slice:nth-of-type(32) {
  transition: all 1.36853726s;
  background-position: 100% 42.85714286%;
  transform: translateX(-8.65839796vw) translateY(-18.97737288vh) rotate(0.93528027turn);
}
.frame .slice:nth-of-type(33) {
  transition: all 1.92076339s;
  background-position: 0% 57.14285714%;
  transform: translateX(33.09149521vw) translateY(16.99727718vh) rotate(0.3295478turn);
}
.frame .slice:nth-of-type(34) {
  transition: all 1.55818582s;
  background-position: 14.28571429% 57.14285714%;
  transform: translateX(41.97978013vw) translateY(-21.66621414vh) rotate(0.95479346turn);
}
.frame .slice:nth-of-type(35) {
  transition: all 1.46509022s;
  background-position: 28.57142857% 57.14285714%;
  transform: translateX(21.77950389vw) translateY(-16.60350612vh) rotate(0.08206135turn);
}
.frame .slice:nth-of-type(36) {
  transition: all 1.65197117s;
  background-position: 42.85714286% 57.14285714%;
  transform: translateX(-39.42475724vw) translateY(-18.05969704vh) rotate(0.00025406turn);
}
.frame .slice:nth-of-type(37) {
  transition: all 1.76452218s;
  background-position: 57.14285714% 57.14285714%;
  transform: translateX(22.50022265vw) translateY(14.67135961vh) rotate(0.68755755turn);
}
.frame .slice:nth-of-type(38) {
  transition: all 1.20762841s;
  background-position: 71.42857143% 57.14285714%;
  transform: translateX(49.58491902vw) translateY(14.1356469vh) rotate(0.80174804turn);
}
.frame .slice:nth-of-type(39) {
  transition: all 1.64427172s;
  background-position: 85.71428571% 57.14285714%;
  transform: translateX(-18.77799759vw) translateY(-17.4447131vh) rotate(0.80780359turn);
}
.frame .slice:nth-of-type(40) {
  transition: all 1.7722496s;
  background-position: 100% 57.14285714%;
  transform: translateX(-10.83112437vw) translateY(-0.05686958vh) rotate(0.38288253turn);
}
.frame .slice:nth-of-type(41) {
  transition: all 1.50335987s;
  background-position: 0% 71.42857143%;
  transform: translateX(-0.19118053vw) translateY(21.82692924vh) rotate(0.79214446turn);
}
.frame .slice:nth-of-type(42) {
  transition: all 1.81182276s;
  background-position: 14.28571429% 71.42857143%;
  transform: translateX(35.17200793vw) translateY(0.68845503vh) rotate(0.88344356turn);
}
.frame .slice:nth-of-type(43) {
  transition: all 1.37169757s;
  background-position: 28.57142857% 71.42857143%;
  transform: translateX(41.37171902vw) translateY(-7.76581208vh) rotate(0.85964375turn);
}
.frame .slice:nth-of-type(44) {
  transition: all 1.72744515s;
  background-position: 42.85714286% 71.42857143%;
  transform: translateX(3.787183vw) translateY(-9.86085241vh) rotate(0.88229062turn);
}
.frame .slice:nth-of-type(45) {
  transition: all 1.78883808s;
  background-position: 57.14285714% 71.42857143%;
  transform: translateX(26.95214659vw) translateY(-3.09151716vh) rotate(0.57258167turn);
}
.frame .slice:nth-of-type(46) {
  transition: all 1.98755075s;
  background-position: 71.42857143% 71.42857143%;
  transform: translateX(-46.75722839vw) translateY(19.32045274vh) rotate(0.57555324turn);
}
.frame .slice:nth-of-type(47) {
  transition: all 1.42411448s;
  background-position: 85.71428571% 71.42857143%;
  transform: translateX(9.48369985vw) translateY(7.32537697vh) rotate(0.14776672turn);
}
.frame .slice:nth-of-type(48) {
  transition: all 1.1189657s;
  background-position: 100% 71.42857143%;
  transform: translateX(-25.58850023vw) translateY(3.16983872vh) rotate(0.24337012turn);
}
.frame .slice:nth-of-type(49) {
  transition: all 1.01234349s;
  background-position: 0% 85.71428571%;
  transform: translateX(40.28073375vw) translateY(9.29751572vh) rotate(0.24011927turn);
}
.frame .slice:nth-of-type(50) {
  transition: all 1.98072534s;
  background-position: 14.28571429% 85.71428571%;
  transform: translateX(-26.1675956vw) translateY(21.02124797vh) rotate(0.62801343turn);
}
.frame .slice:nth-of-type(51) {
  transition: all 1.57455264s;
  background-position: 28.57142857% 85.71428571%;
  transform: translateX(-31.93665604vw) translateY(-6.83326199vh) rotate(0.08107476turn);
}
.frame .slice:nth-of-type(52) {
  transition: all 1.9717476s;
  background-position: 42.85714286% 85.71428571%;
  transform: translateX(-35.375693vw) translateY(-24.1931805vh) rotate(0.58244928turn);
}
.frame .slice:nth-of-type(53) {
  transition: all 1.73252406s;
  background-position: 57.14285714% 85.71428571%;
  transform: translateX(-4.8465447vw) translateY(23.18118956vh) rotate(0.38014291turn);
}
.frame .slice:nth-of-type(54) {
  transition: all 1.24077066s;
  background-position: 71.42857143% 85.71428571%;
  transform: translateX(-9.15113444vw) translateY(24.77273745vh) rotate(0.12040669turn);
}
.frame .slice:nth-of-type(55) {
  transition: all 1.30091065s;
  background-position: 85.71428571% 85.71428571%;
  transform: translateX(3.35576417vw) translateY(-22.761656vh) rotate(0.37354599turn);
}
.frame .slice:nth-of-type(56) {
  transition: all 1.04813604s;
  background-position: 100% 85.71428571%;
  transform: translateX(-9.26034172vw) translateY(-4.09235323vh) rotate(0.93606555turn);
}
.frame .slice:nth-of-type(57) {
  transition: all 1.70694079s;
  background-position: 0% 100%;
  transform: translateX(21.86963468vw) translateY(-11.49395541vh) rotate(0.1946114turn);
}
.frame .slice:nth-of-type(58) {
  transition: all 1.02018612s;
  background-position: 14.28571429% 100%;
  transform: translateX(-38.34273623vw) translateY(-8.48243655vh) rotate(0.00195936turn);
}
.frame .slice:nth-of-type(59) {
  transition: all 1.96842213s;
  background-position: 28.57142857% 100%;
  transform: translateX(13.20332692vw) translateY(-14.90366818vh) rotate(0.67799595turn);
}
.frame .slice:nth-of-type(60) {
  transition: all 1.45724677s;
  background-position: 42.85714286% 100%;
  transform: translateX(-45.01706565vw) translateY(8.32282201vh) rotate(0.92108754turn);
}
.frame .slice:nth-of-type(61) {
  transition: all 1.42669799s;
  background-position: 57.14285714% 100%;
  transform: translateX(-28.92167927vw) translateY(10.52804545vh) rotate(0.99422294turn);
}
.frame .slice:nth-of-type(62) {
  transition: all 1.1212279s;
  background-position: 71.42857143% 100%;
  transform: translateX(16.27911665vw) translateY(19.0245172vh) rotate(0.7536034turn);
}
.frame .slice:nth-of-type(63) {
  transition: all 1.98125289s;
  background-position: 85.71428571% 100%;
  transform: translateX(46.80529591vw) translateY(-16.34566112vh) rotate(0.53404109turn);
}
.frame .slice:nth-of-type(64) {
  transition: all 1.15001315s;
  background-position: 100% 100%;
  transform: translateX(-26.09169987vw) translateY(14.963012vh) rotate(0.8739635turn);
}
/* .frame:hover .slice {
  transform: translate(0, 0) rotate(0);
} */

.blackAndWhite{
  width: 1040px;
  height: 600px;
  position: relative;
  /* border-top: 1px solid black;
  border-bottom: 1px solid black; */
}



.firstzhmudphoto{
  transform: translate(0, 10vh);
  opacity: 0;
  position: relative;
  transition: all 2s;
}

.aloha {
  transform: translate(0, 0vh);
  opacity: 1;
}


.App {
  text-align: center;
}

#MainContainer{
  padding: 0;
}
.text-left{
  font-family: Cormorant meduim;
}
h5 {
  font-family: Clear sans;
}
h1 {
  font-family: Alice regular
}
h2, h3, h4 {
  font-family: Clear sans
}
.itemShippingCosts h4 {
  font-size: 1em;
  margin-bottom: 0.2em;
}
.itemShippingCosts h5 {
  font-size: 0.9em;
}

/* NAV  HEADER*/


.responsive-navbar-nav{
  background-color: #29AAE2;
}
#Header {
  transition: top 1s;
}
#SecondHeader .MainLinksHeader{
  padding: 0;
}
#SecondHeader .nav-link{
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

#SecondHeader {
  font-family: Clear sans;
  font-style: normal;
  background-color: white;
}

.boxheadernodisplay{
  transform: translateY(-100%);
}
.ToMainWebsite{
  position: relative;
}
.ToMainWebsite:hover{
  text-decoration: none;

}
.boxheader {
  display: inline;
  position: fixed;  
  width: 100%;
  left:0;
  transform: translateY(-1%);
  transition: ease 1s;
  transition-delay: .4s;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #29AAE2;
  /* background-color:#29AAE2; */
  background-color:#f8f9fa;

;
}

.nav-tabs .nav-link{
  /* border-radius: 0 5px 10px 10px; */
  border:none;
}

.nav-tabs .nav-link.active{
  /* animation: glows 5s infinite alternate;
  border-color: transparent; */
  color:#29AAE2;

}

.nav-tabs {
  border-bottom: none;
}
.App-link {
  color: #29AAE2;
}




.innerimg {
width: 20em;
height: 15em;
}

.ShopItemImage {
  width: 20em;
  height: 20em;
}

.innerimg2 {
object-fit: cover;
  }

textarea.form-control {
  height: 150px;
}

.card{
  border: none;
  border-radius: 0;
}
.card-img-overlay{
opacity: 0;
}

.card-img-overlay:hover{
opacity: 0.7;
transition: ease-in-out;
transition-delay: 0.2s;
}

.Yekaterina {
  font-family: Alice regular;
  align-self: flex-start;
}
.Yekaterina a:hover{
  text-decoration: none;
  color: black;
}
.stickynavbar{
position: sticky;
top:0
}

#HeaderCarousel {
  height: calc(80vw /2.8);
  width: 100%;
  object-fit: cover;
}
.carousel-inner {
  height: 100%;
}            
.CarouselItem {
  width: 100%;
  height: 100%;
}
.CarouselItem img {

}
.carousel-inner::after {
  display: unset;
  clear: unset;
  content: unset;
}
.carousel-item {
  -webkit-transition: all 1.5s, ease-in-out;
  -moz-transition: all 1.5s ease-in-out;
  -o-transition: all 1.5s ease-in-out;
}
.carousel-fade .carousel-item.active {
  opacity: 1;
  z-index: 1;
  -webkit-transition: all 1.5s, ease-in-out;
  -moz-transition: all 1.5s ease-in-out;
  -o-transition: all 1.5s ease-in-out;
}

.GalleryBackground .carousel-item {
    transition: none;  }


.ShopItemCarousel .carousel-item {
  transition: transform 0.5s ease,-webkit-transform 0.1s ease-in-out;  
}

.d-block {
  width: 100%;

}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0,0,0);
}
#TheMainBanner {
  background-color: seashell;
  font-family: Cormorant meduim;
  font-weight: 500;
  height: 30vh;
}
#firsttext {
  background-color: rgb(255, 255, 255);
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 4em;
}

.ProductsContainer{
  display: flex;
  flex-direction: column;
}
.ItemCartPriceContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ItemCartPrice {
  display: flex;
  flex-direction: row-reverse;
  vertical-align: top;

}

.RemoveFromCartButtonContainer{
  display: flex;
  flex-direction: row-reverse;
}

#firsttext h3 {
  width: 100%;
}
#firsttext h2, h3 {
  font-family: 'Cormorant meduim';
}
#firsttext h4 {
  font-family: 'Cormorant meduim';
}
.lastText{
  max-width: 40vw;
}

#HomeZhmudPhoto{
  padding-right: 0px;
}
/* .MainShopItemCarouselContainer{
width: 860px;
max-width: 860px;
} */

.ShopItem{
  object-fit: cover;
  width: 560px;
  height: 560px;
}

.ShopItemCarousel{
  height: 560px;
  width: 560px;
}

.Item {
  width: 30rem;
  height: 20rem;
  margin: 5vh auto;
}

.figure {
  text-align: center;
  width: 100%;

}
.galleryview{
  position: fixed;
}
.GalleryItemDiv{
  text-align: center;
  position: relative;

}

.GalleryItem {
  position: absolute;
  height: 100%;
  overflow: hidden;
}

.CountryDropdown {
  height: calc(1.5em + .75rem + 2px);
  width: 374px;
  line-height: 1.5;
  color: #495057;
  padding: .375rem .5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: start;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  appearance: textfield;

}

.MainItemDescriptionContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 2em 0 2em;
}

.AddItemToCartButton:hover{
 transition: 1s ease-in-out;
}


.MainItemDescriptionContainer.col{
  
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0; 
    max-width: 100%;
}
.col{
  min-width: unset;
}



.RegionDropDown{
  height: calc(1.5em + .75rem + 2px);
  /* width: 374px; */
  border: 1px solid #ced4da;
  line-height: 1.5;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;

  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #daced1;

  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out}

/* z-index: 10212; */


.glry { 
  overflow: hidden;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: center;
}


.glryimg {
  max-width: 90%;
  max-height: 80%;
  object-fit: cover;

}

.productitem{
  max-width: 50%;
  max-height: 50%;
}
.glry{
  flex-direction: column;
}
.cancelcross{
  margin-right: 10%;
  align-self: flex-end;
  position: relative;
  width: 2em;
  height: 2em;
  z-index: 100022;
}
.carousel-control-prev-icon {
  width: 40px;
  height: 40px;
  color: #fff;
  background-image: url(./mains/pages/arrow_left.png);
}
.carousel-control-next-icon {
  width: 40px;
  height: 40px;
  color: #fff;
  background-image: url(./mains/pages/arrow_right.png);
}
.glrytext {
  color: white;
  position: absolute;
  bottom: 2%;
  text-align: center;

/* z-index: 100023; */


}

 .GalleryBackground{
  position: fixed;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 10001;

} 

.badge {
  position: relative;
  bottom:5px;
  vertical-align: top;
  border-radius: 0;
  /* font-size: 0.5005em; */
  line-height: 1;
  padding: .305rem .5005rem;
  font-weight: bold;
  color: #000;
  background-color: #29AAE2;

}
.ShopItemCarouselToAppend{
  cursor: pointer;
}
.MainShopItemCrossLink {
  width: 0em;
  height: 0em;
}
.CancelcrossShop {
  margin-right: 5%;
  align-self: flex-end;
  width: 0em;
  height: 0em;
  position: relative;
  z-index: 100022;
}

.badge-outline-primary {
  color: #ffffff;
}

.badge.badge-pill {
  border-radius: 10rem
}

a {
  color: black;
  text-decoration: none;

}

a.newover{
  color: white;
  text-decoration: none;
}

.newover {
  position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;

	opacity: 0;
	-moz-opacity: 0;
	filter:alpha(opacity=0);

  -webkit-transition: opacity 0.3s ease-in-out;
	-moz-transition: opacity 0.3s ease-in-out;
	-o-transition: opacity 0.3s ease-in-out;
	transition: opacity 0.3s ease-in-out;
  text-decoration: none;

  background-color: rgb(11, 26, 39, 0.8)
}

.newover:hover{
  opacity: 1;
}

.imgtext {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  text-decoration: none;
  justify-content: center;
}
 .imgtext h3 {
  font-size: 2rem;
}
.imgtext h4 {
  font-size: 1.25rem;
}
.imgtext h5 {
  font-size: 1.5rem;
}
.imgtexttop{
  padding: 10% ;
  text-align: center;
  width: 100%;
  align-self: start;
  align-items: flex-start;
  align-content: flex-start;
}
.imgtextbot{
  padding: 10%;
  width: 100%;
  text-align: center;
  align-self: flex-end;
  align-items: flex-end;
  align-content: flex-end;
}
.OutOfStockImg{
  width: 20%;
}

.clickasd {
  top:0;
  left:0
}

#SuccessResponseForms{
  display: none;
  background-color: seashell;
  position: absolute;
  text-align: center;
  left: calc(50% - 10em);
  padding: 1em;
  margin-top:40vh;
  border: 1px solid black;
  width: 20em;
  z-index: 100;
}
.ItemMainContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10vh;
}
.bubbleWrap{
  /* position: absolute;
  bottom: 0px; */
}

.img-fluid{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.icon-holder{
  width: 5rem;
  height: 5rem;
  padding: 0.5em;
  display: flex;
  
  /* padding-left: 10px;
  padding-bottom: 10px; */


}

.one{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.footerrow{
  justify-content: center;
  text-align: center;
}
.footerIcons{
  text-align: center;
  justify-content: space-around;
  align-content: space-around;
  height: 100%;
  width: 100%;
}



.RemoveFromCartButton{
  color: rgb(20, 104, 139);
  background-color: white;
  padding: 0;
  border: none;

}
.RemoveFromCartButton:hover {
  color:black;
  background-color: white;
}
.RemoveFromCartButtonImage{
  width: 1.5em;
  height: 1.5em;
  vertical-align: bottom;
}
.BorderBottomItemCart{
  border-bottom: 1px solid gray;
}
.BorderTopCart{
  border-top: 1px solid gray;
}
.BorderTopFooter{
  border-top: 1px solid lightgray;
}
.BorderTopFooter h2{
  font-size: 1.2em;
  font-weight: 500;
}
.BorderTopFooter h3{

  font-size: 1em;
}
.BorderTopFooter a {
  color: rgb(68, 68, 184);
}

.DeliveryPriceTooltip{
  vertical-align: unset;
}
.AddItemToCartButton{
  transition: 1s ease-in-out;
}
.TooSmall{
  display: none;
}

/* BOXES */

.BoxContainer {
  position: absolute;
  left:0;
  right: 0;
  top:0
}

.BoxWrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.FirstBoxContainer{
  display: flex;
  height: 110vh;
  width: 100%;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  background: #c2e59c; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #c2e59c, #64b3f4); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #c2e59c, #64b3f4);
}
.FirstBoxPhotoContainer{
  background-image: url("./mains/pages/IMG_5182.jpg");
  background-size: cover;
  width: 360px;
  height: 360px;
  transform: translateY(-50px);
  transition: ease 3s;
  animation: fadein 3s ease-in alternate ;
}
.SecondBoxPhotoContainer{
  background-image: url("./mains/pages/pinkbox.JPG");
  background-size: cover;
  width: 360px;
  height: 360px;
  transform: translateY(-50px);
  transition: ease 3s;
  animation: fadein 3s ease-in alternate ;
}

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes fadein2 {
  from { opacity: 0; }
  to { opacity: 1; }
}

.SecondBoxContainer {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.ThirdBoxContainer {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  justify-content: space-evenly;
  align-items: center;
  height: 80vh;
  width: 100%;
}

.FourthBoxContainer {
  height: auto;
  width: 100%;
  text-align: center;  
  padding-bottom: 10vh;
  padding-top: 10vh;

}
.BoxCol{
 max-width: 30%;
 top:-10%
}

.BoxDescriptionContainer {
  position: absolute;
  top:10%;
}

.BoxText {
  transform: translate(0, -50%);
  transition: ease 3s;
  color: white;
}

#more {display: none;}

a.BoxTextClickHere{
  transform: translate(0, -50%);
  transition: ease 2s;
  color: rgb(59, 153, 187);
}

.FirstBoxFirstTextline{
  transform: translateY(-50px);
  transition: ease 3s;
  color: black;
}

.FirstBoxSecondTextline{
  transition-delay: 2s;
  position: relative;
  bottom: 50px;
  animation: fadein 3s ease-in 2s;
  color: black;
}

.FirstBoxButton{
  transition-delay: 2s;
  position: relative;
  bottom: 50px;
  animation: fadein 2s ease-in-out 2s;

}
.CommonButton{
  padding: .375rem .75rem;
  color: #fff;
  background: #c2e59c; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #64b3f4, #c2e59c); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,#64b3f4, #c2e59c );
  border: 1px rgb(255, 255, 255);
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;

}
.SmallArrow{
  width: 1rem;
  height: 1rem;
}

.HiddenStuff{
  opacity: 0;
}

.ReadMoreButton{
  transition-delay: 2s;
  animation: fadein 3s ease-in 2s;
  background-color: transparent;
}

.HerePointer{
  cursor: pointer;
}
.totalCost{
  float: right;
  font-weight: bold;
}


/* RESPONSIVE */
@media (min-width: 992px){
  .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0;
      padding-left: 0;
  }}

  @media only screen and (min-width: 769px) and (max-width: 1025px) {
    #firsttext h3 {
      font-size: 1.5rem;
    }
    .totalCost{
      float: unset;
    }
    .container{
      max-width: 960px;
    }
    .footerrow{
      text-align: center;
      justify-content: center;
    }
    .imgtext {
      justify-content: space-around;
    }
    .icon-holder{
      max-width: 3rem;
      max-height: 3rem;
      display: inline-block;
    }
    .row{
      margin-left: 0;
      margin-right: 0;
    }
    img.icon {
      width: 3em;
    }

  }
/* FIX THAT SHIT */

@media only screen and (max-width: 768px) {
  .Yekaterina {
    align-self: center;
  }
  #firsttext{
    padding: 1em;
  }
  .bubbleWrap{
    position: relative;
    margin-top: 2em
  }
  .row{
    margin-left: 0;
    margin-right: 0;
  }
  #HomeZhmudPhoto{
    padding-right: 15px;
  }

  .TooLargeFor{
    display: none;
  }
  .TooSmall{
    display: flex;
    width: 100%;
  }
  #MainContainer.container{
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;

  }
  .glryimg {
    max-width: 100%;
    width: 100%;
    padding-left: 0;
    object-fit: contain;
  }
  .CancelcrossShop{
    position: relative;

  }
  .glry {
    flex-direction: column;
  }


  .cancelcross{
    margin-right: 0;
    color: white !important;
    position: relative;
    bottom: 0;
    align-self: flex-end;
    padding: 0.1em;
  }
  .productitem {
    max-width: 100%;
    max-height: 100%;
  }
  #HeaderCarousel { 
    width: auto;
    height: auto;
    object-fit: cover;
  }
  .CarouselItem {
    width: 100%;
    height: auto;
  }

  .ShopItem{
    object-fit: cover;
    width: 360px;
    height: 360px;

  }
  .ItemMainContainer{
    
    flex-direction: column;
    align-items: center;
  }
  .MainItemDescriptionContainer{

    max-width: 80%;
    margin: 0;
    margin-top: 2em;
    height: auto;
  }
  .MainShopItemCarouselContainer .carousel{
    width: auto
  }
  .MainShopItemCarouselContainer {
    height: 360px;
    width: 360px;
  }
  .ShopItemCarousel {
    height: 360px;
    width: 360px;
  }
  .ItemMainContainer{
    margin-bottom: 0;
  }
  .ItemMainContainer .col {
    width: auto;
  }
  .img-fluid{
    height: 100%;
    object-fit: cover;
  }
  .footerrow{
    text-align: center;
    justify-content: center;

  }
  .footerIcons{
    text-align: center;
    justify-content: center;
  }

  .icon-holder{
    width: 3rem;
    height: 3rem;
    display: inline-block;
    margin: 0.5em;
  }
  img.icon {
    width: 3em;
  }
  .MainShopItemCarouselContainer{
    text-align: center;
  }
  .MainItemDescriptionContainer{
    text-align: center;
  }
  .MainItemDescriptionContainer .row{
    display: inline;
  }
  .navbar-toggler{
    border: none;
  }

  .CarouselItem {
    width: 100%;
    height: 100%;
  }
  .CarouselItem img {
    object-fit: contain;
  }
  .CartItemContainer {
    flex-direction: column;
  }
  .ItemCartPrice,.RemoveFromCartButtonContainer {
    flex-direction: row;
  }
  .innerimg2{
    width: 100%;
  }
  .ml-3, .mx-3,.RegionDropDown.ml-2, .mx-2 {
  margin-left: 0 !important;
  }

  .imgtext h3 {
    font-size: 1.5rem;
  }
  .imgtext h4 {
    font-size: 1rem;
  }
  .imgtext h5 {
    font-size: 1rem;
  }
  .imgtexttop{
    max-height: 50%;
    padding: 0 15%;
    text-align: center;
    width: 100%;
    align-self: start;
    align-items: flex-start;
    align-content: flex-start;
  }
  .imgtextbot{
    padding: 10% 15%;
    width: 100%;
    text-align: center;
    align-self: flex-end;
    align-items: flex-end;
    align-content: flex-end;
  }

}
